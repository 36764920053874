.container
  width: 100%
  padding: 20px

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px

  .countdown
    display: flex
    align-items: center
    gap: 10px

    img
      width: 30px
      margin-right: 0.3rem

    p
      margin: 0
      display: flex
      align-items: center
      gap: 10px

.cardsGrid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 20px
  padding: 10px

.card
  border: 1px solid #e8e8e8
  border-radius: 8px
  padding: 1rem !important
  background: white
  display: flex
  flex-direction: column
  gap: 16px
  font-family: Poppins-Regular, sans-serif
  transition: .3s ease-in-out transform

  &:hover
    transform: translateY(-5px)

.otherCoins
  border-style: dashed
  justify-content: center
  align-items: center
  border-spacing: .3rem

  .otherCoinsBtn
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer

    p
      text-align: center

.cardHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

  .symbolInfo
    h3
      font-size: 18px
      font-weight: 600
      margin: 0

.tradeButton
  padding: 8px 24px
  border-radius: 4px
  font-weight: 500
  border: none
  cursor: pointer

  &.buy
    background: #40b86d
    color: white

  &.sell
    background: #e74c3c
    color: white

  &.hold
    background: #3498db
    color: white

.signalSection
  display: flex
  flex-direction: column

.signal
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin-bottom: .5rem
  background: #f8f9fa
  padding: 12px
  border-radius: 6px

  .signalLabel
    font-weight: 500
    color: #666

  .signalInfo
    display: flex
    align-items: center
    gap: 6px
    font-size: 14px

    .signalType
      font-weight: 500

      &.buyStatus
        color: #40b86d

      &.sellStatus
        color: #e74c3c

      &.holdStatus
        color: #3498db

    .confidence
      color: #666

    .time
      color: #999


.strongSignal
  background: #eef9ff
  position: relative

  .signalLabel
    font-size: 16px
    color: #0d0d0d

  &::before
    content: ""
    height: 100%
    width: 3px
    background-color: #0C4CFC
    border-radius: 8px 0 0 8px
    position: absolute
    left: 0
    top: 0

.poorSignal
  font-style: italic

.infoSection
  display: grid
  gap: 8px

  > div
    display: flex
    justify-content: space-between
    align-items: center

    > span:first-child
      color: #666
      font-weight: 500

    > span:last-child
      font-weight: 500

.runAiButton
  width: 100%
  padding: 12px
  background: #f0f2f5
  border: none
  border-radius: 4px
  color: #1a73e8
  font-weight: 500
  cursor: pointer
  margin-top: auto
  transition: all 0.2s

  &:hover:not(:disabled)
    background: #e8eaed

  &:disabled
    background: #f5f5f5
    color: #999
    cursor: not-allowed

.priceSection
  margin-top: 15px

  .currentPrice
    margin-bottom: 15px

    h4
      display: flex
      align-items: center
      gap: 8px
      margin-bottom: 5px

      svg
        cursor: pointer

        &:hover
          opacity: 0.7

.limits
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 15px

  h4
    margin-bottom: 5px
    font-size: 14px
    color: #666

.buy
  background: #70AF85
  color: white
  padding: 6px 12px
  border-radius: 4px
  font-weight: 500

.sell
  background: #EF4B4B
  color: white
  padding: 6px 12px
  border-radius: 4px
  font-weight: 500

.hold
  background: #82A0D8
  color: white
  padding: 6px 12px
  border-radius: 4px
  font-weight: 500

.hideContent
  padding: 0
  cursor: pointer
  background-color: #bdc3c7 !important
  border-radius: .3rem

.timeAgo
  font-size: 12px
  color: #666
  margin-top: 5px

.infoItem
  display: flex
  justify-content: space-between
  align-items: center
  gap: 8px

.skeleton
  width: 100%
  height: 100%
  background: #f0f0f0
  border-radius: 4px
  animation: pulse 1.5s infinite

  div
    width: 100%
    height: 100%

    span
      display: block
      width: 100%
      height: 100%
      background: #e0e0e0
      border-radius: 4px

@keyframes pulse
  0%
    opacity: 0.6
  50%
    opacity: 0.8
  100%
    opacity: 0.6 